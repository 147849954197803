<template>
  <main class="page category-open-page">
    <div class="container-padding">
      <div ref="productsList" class="page__inner category-open-page__inner">
        <h1 class="title">{{ pageTitle }}</h1>
        <HotLinksComponent :list="hot_links" />
        <div class="category-open-page__content">
          <CatalogFiltersComponent @filter="filter" />
          <div class="category-open-page__products-wrap">
            <button @click="showFiltersMenu" class="btn btn--md btn--main category-open-page__filters-mobile">
              Фильтры
            </button>
            <SliderComponent
              v-if="discount_products.data.length && !isDiscountPage"
              :items="discount_products.data"
              v-slot="{ item }"
              :slider-options="sliderSaleOptions"
              title="Акции"
              :route="{ name: 'category-open', query: { discount: true } }"
              button-title="Все товары"
            >
              <CardComponent :data="item" :grid="{}" />
            </SliderComponent>
            <div v-if="!filterLoading" class="category-open-page__products">
              <template v-if="previewProduct">
                <CardComponent v-for="_ in 9" :key="_" :data="previewProduct" />
              </template>
              <template v-else-if="products && products.data && products.data.length">
                <CardComponent v-for="(item, i) in products.data" :key="i" :data="item" />
              </template>
              <span v-else>Товары отсутствуют</span>
            </div>
            <IconComponent v-if="filterLoading" class="category-open-page__loading" name="loading" />
            <LoadingLogoComponent v-if="hasMorePages && !filterLoading" class="category-open-page__loading" />
            <ObserverComponent @intersect="paginate" />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import CatalogFiltersComponent from "./components/CategoryFiltersComponent.vue";
import CardComponent from "components/CardComponent.vue";
import CATEGORY_OPEN_PAGE_STORE from "@/graphql/pages/store/CategoryOpenPage.graphql";
import CATEGORY_OPEN_PAGE_LANDING from "@/graphql/pages/landing/CategoryOpenPage.graphql";
import ADMIN_PRODUCT from "@/graphql/queries/admin/admin_products_item.graphql";
import HotLinksComponent from "components/HotLinksComponent.vue";
import ObserverComponent from "components/ObserverComponent.vue";
import IconComponent from "components/IconComponent.vue";
import FilterMenu from "components/menus/components/FilterMenu.vue";
import SliderComponent from "components/SliderComponent.vue";
import LoadingLogoComponent from "components/LoadingLogoComponent.vue";

let sortTypes = [
  {
    id: 1,
    ordering: { orderColumn: "created_at", orderBy: "desc" },
    title: "По умолчанию",
  },
  {
    id: 2,
    ordering: {
      orderColumn: "in_popular",
      orderBy: "desc",
    },
    title: "По популярности",
  },
  {
    id: 3,
    ordering: {
      orderColumn: "price",
      orderBy: "asc",
    },
    title: "Вначале дешевые",
  },
  {
    id: 4,
    ordering: {
      orderColumn: "price",
      orderBy: "desc",
    },
    title: "Вначале дорогие",
  },
];

let _query = {};

function parseParams(query) {
  Object.keys(query).forEach((key) => {
    try {
      query[key] = JSON.parse(query[key]);
    } catch (e) {
      return null;
    }
  });
  return query;
}

export default {
  name: "CategoryOpenPage",
  async asyncData({ apollo, store, route }) {
    let query = route.query;
    // _query = parseParams(route.query);
    if (query.catalog_product_id && query.token) {
      await apollo.clients.admin
        .query({
          query: ADMIN_PRODUCT,
          variables: {
            id: parseInt(query.catalog_product_id),
          },
          context: {
            headers: {
              Authorization: "Bearer " + query.token,
            },
          },
        })
        .then(({ data }) => {
          this.previewProduct = data.admin_products_item;
        });
    } else {
      let token = store.state.auth_token;
      await apollo.clients.store
        .query({
          query: CATEGORY_OPEN_PAGE_STORE,
          variables: {
            first: 9,
            price_min: store.state.variables.price_from,
            price_max: store.state.variables.price_to,
            countries: store.state.variables.countries,
            category_link: route.params.link,
            title: query.title,
            product_group_id: parseInt(query.product_group_id),
            discount: !!query.discount,
            brands: store.state.variables.countries,
            ordering: store.state.variables.sortBy,
            // ..._query,
          },
          context: {
            headers: {
              Authorization: "Bearer " + token,
            },
          },
        })
        .then(({ data }) => {
          store.state.category_open_page.brands = data.brands;
          store.state.category_open_page.countries = data.countries;
          store.state.category_open_page.products = data.products_paginate;
          store.state.category_open_page.category = data.category;
          store.state.category_open_page.product_group = data.product_group;
          store.state.category_open_page.discount_products = data.discount_products;
        });
    }
    await apollo.clients.landing
      .query({
        query: CATEGORY_OPEN_PAGE_LANDING,
      })
      .then(({ data }) => {
        store.state.category_open_page.hot_links = data.hot_links;
      });
  },
  data() {
    return {
      page: 1,
      first: 9,
      query: _query,
      formData: null,
      filterLoading: false,
      paginateLoading: false,
      previewProduct: null, // предпросмотр продукта в каталоге
      activeSort: sortTypes[0],
      sortTypes: sortTypes,
      params: {},
      sliderSaleOptions: {
        slidesPerView: 1.3,
        spaceBetween: 10,
        breakpoints: {
          540: {
            slidesPerView: 1.5,
            spaceBetween: 15,
          },
          800: {
            slidesPerView: 1.5,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 2.2,
            spaceBetween: 24,
          },
        },
      },
    };
  },
  beforeRouteLeave(to, from, next) {
    this.$store.state.variables = {};
    next();
  },
  watch: {
    "$store.state.variables": {
      handler() {
        this.filter();
      },
      deep: true,
    },
  },
  computed: {
    products() {
      return this.$store.state.category_open_page.products;
    },
    category() {
      return this.$store.state.category_open_page.category || {};
    },
    product_group() {
      return this.$store.state.category_open_page.product_group || {};
    },
    hot_links() {
      return this.$store.state.category_open_page.hot_links;
    },
    hasMorePages() {
      return this.$store.state.category_open_page.products.paginatorInfo.hasMorePages;
    },
    discount_products() {
      return this.$store.state.category_open_page.discount_products;
    },
    isDiscountPage() {
      return this.$route.query.discount;
    },
    pageTitle() {
      let query = this.$route.query;
      let total = this.products?.paginatorInfo?.total || 0;
      if (query.discount) {
        return "Акции";
      } else if (query.title) {
        return `Найдено ${total} ${this.$options.filters.plural_word(
          total || 0,
          "товар",
          "товара",
          "товаров"
        )} товаров по запросу “${query.title}”`;
      } else if (this.product_group && this.product_group.title) {
        return this.product_group.title;
      } else if (this.category && this.category.title) {
        return this.category.title;
      }
      return null;
    },
  },
  methods: {
    paginate() {
      if (!this.paginateLoading && this.hasMorePages) {
        this.page += 1;
        this.paginateLoading = true;
        this.load(this.$store.state._loading_types.LOAD_MORE);
      }
    },
    async filter() {
      this.page = 1;
      this.filterLoading = true;
      await this.load();
      // this.scrollUp();
    },
    async changeSort(sort) {
      this.activeSort = sort;
      this.page = 1;
      // this.paginateLoading = true;
      await this.load();
    },
    async load(loadingType) {
      let query = this.$route.query;
      let token = this.$store.state.auth_token;
      await this.$apollo.provider.clients.store
        .query({
          query: CATEGORY_OPEN_PAGE_STORE,
          variables: {
            page: this.page,
            first: this.first,
            price_min: this.$store.state.variables.price_from,
            price_max: this.$store.state.variables.price_to,
            countries: this.$store.state.variables.countries,
            brands: this.$store.state.variables.brands || query.brands,
            category_link: this.$route.params.link,
            title: query.title,
            product_group_id: parseInt(query.product_group_id),
            discount: !!query.discount,
            ordering: this.$store.state.variables.sortBy
              ? [this.$store.state.variables.sortBy?.value || this.$store.state.variables.sortBy]
              : undefined,
            // ...this.params,
            // ...this.$store.state.variables,
          },
          context: {
            headers: {
              Authorization: "Bearer " + token,
            },
          },
        })
        .then(({ data }) => {
          this.$store.state.category_open_page.brands = data.brands;
          this.$store.state.category_open_page.countries = data.countries;
          this.$store.state.category_open_page.category = data.category;
          this.$store.state.category_open_page.product_group = data.product_group;
          if (loadingType === this.$store.state._loading_types.LOAD_MORE) {
            this.$store.state.category_open_page.products.data.push(...data.products_paginate.data);
            this.$store.state.category_open_page.products.paginatorInfo =
              data.products_paginate.paginatorInfo;
          } else {
            this.$store.state.category_open_page.products = data.products_paginate;
          }
        })
        .finally(() => {
          this.paginateLoading = false;
          this.filterLoading = false;
        });
    },
    showFiltersMenu() {
      this.$store.state._menus.push({
        component: FilterMenu,
      });
    },
  },
  metaInfo() {
    let query = this.$route.query;
    let title = "";
    if (query.discount) {
      title = "Акции";
    } else {
      title = query.title
        ? query.title
        : this.$store.state.category_open_page.product_group?.title ||
          this.$store.state.category_open_page.category.title;
    }
    return {
      title,
      meta: [
        {
          vmid: "og:title",
          name: "og:title",
          content: title,
        },
      ],
    };
  },
  components: {
    LoadingLogoComponent,
    SliderComponent,
    IconComponent,
    ObserverComponent,
    HotLinksComponent,
    CardComponent,
    CatalogFiltersComponent,
  },
};
</script>

<style lang="stylus">
.category-open-page {
  &__inner {
    grid-gap 24px
  }

  &__content {
    display grid
    grid-template-columns: minmax(260px, 312fr) 984fr;
    align-items start
    gap 24px
    +below(990px) {
      grid-template-columns 1fr
    }
  }

  &__products-wrap {
    display flex
    flex-direction column
    gap: 20px
  }

  &__filters-mobile {
    align-self flex-end
    +above(991px) {
      display none
    }
    +below(420px) {
      align-self stretch
    }
  }

  &__products {
    display grid
    grid-template-columns repeat(3,1fr)
    row-gap 32px
    column-gap 24px
    +below(1200px) {
      gap 15px
    }
    +below(580px) {
      grid-template-columns repeat(2, 1fr)
    }
  }

  &__loading {
    width 100px
    height 100px
    margin auto
  }

  .section-inner {
    gap 0
  }
}
</style>
